import QuoteGenerator from './component/QuoteGenerator';

function App() {
  return (
    <div>
      <QuoteGenerator />
    </div>
  );
}


export default App;
